import React from "react";
import { Link } from "gatsby";
import Carousel from 'react-images'

const MDXComponents = {
  a: (props) => <Link {...props} />,
  slider: (props) => {
    return (
      <div className={"slider"}>
        {props.children}
      </div>
    )
  }
};

export default MDXComponents;
