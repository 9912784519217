import React, { useEffect } from "react";
import { graphql } from "gatsby";
import MdxProvider from "@/components/MDXProvider";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from "@/components/Layout";
// import SEO from '@/components/seo';
import Link from "@/components/GatsbyLink";
import kebabCase from "lodash.kebabcase";
import BackButton from '@/components/BackButton';

const Foo = () => {
  return (<h1>Slider</h1>)
}

const PostTemplate = ({ data }) => {
  const postNode = data.mdx;
  const post = data.mdx.fields;
  const { tags, category } = postNode.frontmatter;
  const postTitle = data.mdx.frontmatter.title;
  const postSlug = postNode.fields.slug;
  // const pageTitle = `${postTitle} - ${siteTitle}`;

  const puter = () => {
    // figure out what device form factor this is
    // if browser is too old for matchMedia, use mobile layout
    let isMobile = !!(window.matchMedia && window.matchMedia("(max-width: 850px)").matches);
    window.formFactor = isMobile ? 'mobile' : 'desktop';

    if(window.formFactor == 'desktop'){

        document.documentElement.addEventListener('wheel', event => {
            event.preventDefault();
            if(event.deltaX){
                document.querySelector('.post-lcm').scrollBy(event.deltaX, 0);
            } else if(event.deltaY){
                document.querySelector('.post-lcm').scrollBy(event.deltaY, 0);
            }
        })

        document.documentElement.addEventListener('keyup', event => {
            if(event.key == " " || event.code == "Space" || event.keyCode == 32){
                event.preventDefault();
                document.querySelector('.post-lcm').scrollBy({
                    left: 750,
                    behavior: 'smooth'
                });
            }
        })
    }}

  useEffect(() => {
    if(typeof window !== 'undefined' && window.document) {
      if(postSlug == "lcm") puter();
   }
  })

  return (
    <MdxProvider components={{Foo}}>
      <Layout>
        <BackButton />
        {/* <SEO postNode={data.mdx} postSEO postPath={data.mdx.fields.slug} /> */}
        <article className={`my-6 prose prose-green lg:prose-xl post post-${data.mdx.fields.slug}`}>
          <MDXRenderer>{postNode.body}</MDXRenderer>
        </article>
      </Layout>
    </MdxProvider>
  );
};

export const query = graphql`
  query PostBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      ...post
    }
  }
`;

export default PostTemplate;
